import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import SearchCityComponent from '@/components/SearchCityComponent.vue';
import { enderecoStore } from '@/store';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let EnderecoComponent = class EnderecoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.disabled = false;
        this.cep = '';
        this.bairro = '';
        this.rua = '';
        this.numero = '';
        this.cidadeSelected = { codigo_municipio_completo: null };
        this.opennedOnMaps = false;
        this.latitudeLongitude = '';
    }
    async onChangeCep(val) {
        if (val) {
            if (val.length === 9) {
                val = val.replace('-', '');
                const endereco = await enderecoStore.getEndereco(val);
                // @ts-ignore
                if (endereco.erro) {
                    return;
                }
                // @ts-ignore
                if (endereco.bairro) {
                    // @ts-ignore
                    this.bairro = endereco.bairro;
                }
                // @ts-ignore
                if (endereco.logradouro) {
                    // @ts-ignore
                    this.rua = endereco.logradouro;
                }
                if (!this.loading) {
                    this.cidadeSelected = {
                        // @ts-ignore
                        name: endereco.localidade + ' - ' + endereco.uf,
                        // @ts-ignore
                        nome_municipio: endereco.localidade,
                        // @ts-ignore
                        nome_uf: endereco.uf,
                        // @ts-ignore
                        codigo_municipio_completo: endereco.ibge,
                    };
                }
                this.loading = true;
                this.$nextTick(() => {
                    this.loading = false;
                });
            }
        }
    }
    async onPropDisabled(val) {
        this.disabled = val;
    }
    async changeLoadingStatus(status) {
        this.loading = status;
    }
    async submit() {
        this.loading = true;
        this.$emit('submit');
    }
    onClickOpenMaps() {
        let searchPiece = '';
        if (this.cep) {
            searchPiece += this.cep + '+';
        }
        if (this.bairro) {
            for (const word of this.bairro.split(' ')) {
                searchPiece += word + '+';
            }
        }
        if (this.rua) {
            for (const word of this.rua.split(' ')) {
                searchPiece += word + '+';
            }
        }
        if (this.numero) {
            searchPiece += this.numero + '+';
        }
        if (this.cidadeSelected) {
            // @ts-ignore
            for (const word of this.cidadeSelected.nome_municipio.split(' ')) {
                searchPiece += word + '+';
            }
        }
        const searchUrl = `https://www.google.com.br/maps/search/${searchPiece}/`;
        window.open(searchUrl, '_blank');
        this.opennedOnMaps = true;
    }
};
__decorate([
    Prop({ default: false })
], EnderecoComponent.prototype, "propDisabled", void 0);
__decorate([
    Prop({ default: true })
], EnderecoComponent.prototype, "propDisabledValidator", void 0);
__decorate([
    Prop({ default: false })
], EnderecoComponent.prototype, "propMostrarNoMaps", void 0);
__decorate([
    Watch('cep', { immediate: true, deep: true })
], EnderecoComponent.prototype, "onChangeCep", null);
__decorate([
    Watch('propDisabled')
], EnderecoComponent.prototype, "onPropDisabled", null);
EnderecoComponent = __decorate([
    Component({
        components: {
            SearchCityComponent,
            ValidationObserver,
            ValidationProvider,
        },
    })
], EnderecoComponent);
export default EnderecoComponent;
