export function newPontoParada() {
    return {
        bairro: '',
        cep: '',
        codigo_municipio_completo: null,
        numero: '',
        logradouro: '',
        observacao: '',
    };
}
