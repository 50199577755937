import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { pontoParadaStore } from '@/store';
import SearchCityComponent from '@/components/SearchCityComponent.vue';
import EnderecoComponent from '@/components/EnderecoComponent.vue';
import SaveButton from '@/components/SaveButton.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import { newPontoParada } from '@/interfaces/pontoParada';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.id = null;
        this.entityObject = null;
        this.search = '';
        this.selectedItem = {};
        this.cidadeSelected = { codigo_municipio_completo: null };
    }
    get pontosParada() {
        return pontoParadaStore.pontosParada;
    }
    async submit() {
        const isValid = await this.$refs.observer.validate();
        // @ts-ignore
        const isValidEndereco = await this.$refs.endereco.$refs.observer.validate();
        // @ts-ignore
        const isValidCidade = await this.$refs.endereco.$refs.cidadeComponent.validateField();
        if (!isValid || !isValidEndereco || !isValidCidade) {
            // @ts-ignore
            await this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        this.entityObject.logradouro = this.$refs.endereco.rua;
        this.entityObject.cep = this.$refs.endereco.cep;
        this.entityObject.bairro = this.$refs.endereco.bairro;
        this.entityObject.numero = this.$refs.endereco.numero;
        this.entityObject.codigo_municipio_completo =
            this.$refs.endereco.cidadeSelected.codigo_municipio_completo;
        if (this.id) {
            const result = await pontoParadaStore.updatePontoParada(this.entityObject);
        }
        else {
            const result = await pontoParadaStore.createPontoParada(this.entityObject);
            this.id = result;
            this.entityObject.id = result;
        }
        // @ts-ignore
        await this.$refs.saveButton.changeLoadingStatus(false);
        await this.$router.push({ name: 'main-pontos-parada' });
    }
    async mounted() {
        if (this.$router.currentRoute.params.id) {
            this.entityObject = await pontoParadaStore.getPontoParada(parseInt(this.$router.currentRoute.params.id, 0));
            this.id = this.$router.currentRoute.params.id;
            setTimeout(() => {
                this.$refs.endereco.cep = this.entityObject.cep;
                this.$refs.endereco.bairro = this.entityObject.bairro;
                this.$refs.endereco.numero = this.entityObject.numero;
                this.$refs.endereco.rua = this.entityObject.logradouro;
                this.$refs.endereco.cidadeSelected.codigo_municipio_completo =
                    this.entityObject.codigo_municipio_completo;
            }, 100);
        }
        else {
            this.entityObject = newPontoParada();
        }
    }
};
Create = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            SearchCityComponent,
            EnderecoComponent,
            SaveButton,
        },
    })
], Create);
export default Create;
